import { Overlay, Text, Button } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Consent: React.FC = () => {

    const CONSENT_KEY = 'ok';

    const [t] = useTranslation("global");

    const [consentLabel, setConsented] = useState<string | null>(localStorage.getItem(CONSENT_KEY))

    function consent() {
        localStorage.setItem(CONSENT_KEY, '');
        setConsented('');
    }

    return (
        <>
            {
                consentLabel === null &&
                <Overlay
                    opacity={0.8}
                    h="auto"
                    mih={50}
                    w="100%"
                    pos="fixed"
                    top="unset"
                    bottom={0}
                    display="flex"
                    style={{ justifyContent: 'right', fontSize: '14px' }}
                    px={20}
                    py={10}
                    >
                        <Text c="#fff" mr={30}>{t("tracking_policy")}</Text>
                        <Button size="xs" onClick={consent}>Ok</Button>
                </Overlay>
            }
        </>
    );
}
 
export default Consent;