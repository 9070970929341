import global_en from "./en/global.json";
import global_hr from "./hr/global.json";

import tools_en from "./en/tools.json";
import tools_hr from "./hr/tools.json";

import home_en from "./en/home.json";
import home_hr from "./hr/home.json";

import requestAnOffer_en from "./en/request-an-offer.json";
import requestAnOffer_hr from "./hr/request-an-offer.json";

import aboutUs_en from "./en/about-us.json";
import aboutUs_hr from "./hr/about-us.json";

const TranslationsModule = () => {

    const translations = {
        interpolation: { escapeValue: false },
        lng: "hr",
        resources: {
          en: {
            global: global_en,
            tools: tools_en,
            home: home_en,
            requestAnOffer: requestAnOffer_en,
            aboutUs: aboutUs_en
          },
          hr: {
            global: global_hr,
            tools: tools_hr,
            home: home_hr,
            requestAnOffer: requestAnOffer_hr,
            aboutUs: aboutUs_hr
          }
        }
    };

    return translations;
}
 
export default TranslationsModule;