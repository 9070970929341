import { useEffect, useState } from 'react';
import { UnstyledButton, Menu, Image, Group, createStyles } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import images from '../assets/images/language-icons/icons';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles(() => ({
    control: {
        width: 'rem(200px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 'var(--mantine-spacing-xs) var(--mantine-spacing-md)',
        borderRadius: 'var(--mantine-radius-md)',
        border: 'rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-6))',
        transition: 'background-color 150ms ease',
        backgroundColor: 'light-dark(var(--mantine-color-white), var(--mantine-color-dark-6))',
      
        '&[data-expanded]': {
          backgroundColor: 'light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5))',
        },
      
        '@mixin hover': {
          backgroundColor: 'light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5))',
        }
      },
      
      label: {
        fontWeight: 500,
        fontSize: 'var(--mantine-font-size-sm)',
      },
      
      icon: {
        transition: 'transform 150ms ease',
        transform: 'rotate(0deg)',
      
        '[data-expanded] &': {
          transform: 'rotate(180deg)'
        }
    },

    hide: {
        display: 'none'
    }
}));

const data = [
    { label: 'Hrvatski', short: 'hr', image: images.croatian },
    { label: 'English', short: 'en', image: images.english },
    //{ label: 'German', image: images.german },
];

const LanguagePicker: React.FC<{ visible?: boolean }> = ({ visible = true }) => {

    const [, i18n] = useTranslation("global");

    const handleChangeLanguage = (lang: LanguagePickerProps) => {
        setSelected(lang);
        i18n.changeLanguage(lang.short);
    }

    const { classes } = useStyles();

    const [opened, setOpened] = useState(false);
    const [selected, setSelected] = useState(data[0]);

    useEffect(() => {
        setOpened(visible);
    }, [visible])

    const items = data.map((item) => (
        <Menu.Item
            key={item.label}
            onClick={() => handleChangeLanguage(item)}
            icon={<Image src={item.image} alt={item.label + 'Language'} width={22} height={22} />}
            >
            {item.label}
        </Menu.Item>
    ));

    return (
        <div className={visible ? '' : classes.hide}>
            <Menu
                onOpen={() => setOpened(true)}
                onClose={() => setOpened(false)}
                radius="md"
                width="target"
                withinPortal
                >

                <Menu.Target>

                    <UnstyledButton className={classes.control} data-expanded={opened || undefined}>

                        <Group spacing="xs">
                            <Image src={selected.image} width={22} height={22} />
                            <span className={classes.label}>{selected.label}</span>
                        </Group>

                        <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />

                    </UnstyledButton>

                </Menu.Target>

                <Menu.Dropdown className={visible ? '' : classes.hide}>{items}</Menu.Dropdown>
            </Menu>
        </div>
    );
}

export default LanguagePicker;

interface LanguagePickerProps {
    label: string, 
    short: string, 
    image: string
}