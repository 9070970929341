import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Home = lazy(() => import('./pages/Home/Home'));
const Projects = lazy(() => import('./pages/Projects'));
const RequestAnOffer = lazy(() => import('./pages/RequestAnOffer'));
const Blog = lazy(() => import('./pages/Blog'));
const About = lazy(() => import('./pages/About'));

export default function AllRoutes() {
  return (      
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/request-an-offer" element={<RequestAnOffer />} />
      <Route path="/blog" element={<Blog />} />
      <Route path={"/about"} element={<About />} />
    </Routes>
  );
}