import { Container, rem, createStyles, Flex, Text, Anchor } from '@mantine/core';
import { IconMail, IconPhone } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
  
  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: 0,
    borderTop: `${rem(1)} solid light-dark(${theme.colors.gray[2]}, ${theme.colors.dark[4]})`,

    '@media (max-width: var(--mantine-breakpoint-sm))': {
        flexDirection: 'column',
    },

    '& p': {
        marginRright: '40px',
    }
  },
  
  social: {
      '@media (max-width: var(--mantine-breakpoint-sm))': {
          marginTop: 'var(--mantine-spacing-xs)'
      }
  }
}));

const Footer: React.FC = () => {

  const { classes } = useStyles();

  return (
    <footer>

      <Container size="lg" p="xl">

        <Flex gap={15} align="center">
          <IconMail />
          <Anchor href='mailto:{{info@oakbeam.hr}}' title='Contact Email'>info@oakbeam.hr</Anchor>
        </Flex>

        <Flex gap={15} align="center">
          <IconPhone />
          <Text component='label'>+385 95 527 9214</Text>  
        </Flex>
        
        {/* <Container className={classes.inner}>
          <div className={classes.groups}>{groups}</div>
        </Container> */}

        <Container className={classes.afterFooter}>
          
          <Text c="dimmed" size="sm">
            © 2024 {/* Oak Beam Software Development. */} All rights reserved.
          </Text>

          {/* <Flex className={classes.social} justify="flex-end" wrap="nowrap">
            <ActionIcon size="lg" color="gray" variant="subtle">
              <IconBrandFacebook style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            </ActionIcon>
          </Flex> */}

        </Container>
      </Container>

    </footer>
  );
}

export default Footer;