import ReactDOM from "react-dom/client";
import App from "./App";
import {  } from './utils/initializeFirebase';

import TranslationsModule from "./translations/TranslationsModule";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";


const translations = TranslationsModule();
i18next.init(translations);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
);
