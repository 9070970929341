import { Burger, Divider, Text, Drawer, MediaQuery, UnstyledButton, Header as MantineHeader, createStyles, ActionIcon, Image, Flex, Stack, Center } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import LanguagePicker from './LanguagePicker';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/logo/icon-96-96.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import googleAnalyticsLogger from '../services/Email/googleAnalyticsLogger';

const useStyles = createStyles((theme) => ({
  headerStyles: {
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center',
    background: theme.colors.obBlue[9]
  },

  mainLink: {
    textTransform: 'uppercase',
    fontSize: 'var(--mantine-font-size-xl)',
    color: `light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1))`,
    padding: 'var(--mantine-spacing-sm)',
    fontWeight: 700,
    borderBottom: '2px solid transparent',
    transition: 'border-color 100ms ease, color 100ms ease',

    '& a': {
        fontSize: 'var(--mantine-font-size-md)',
        color: 'light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-1))',
        textDecoration: 'none',
    },
  
    '&:hover': {
      textDecoration: 'none',
      color: '#fff',
    },
    
  
    '&[data-active]': {
      color: 'light-dark(var(--mantine-color-black), var(--mantine-color-white))',
      borderBottomColor: 'var(--mantine-color-blue-6)',
    }
}
}));

const Header = () => {

  const { classes, theme } = useStyles();

  const navigate = useNavigate();

  const navigateTo = (path: string) => {
      navigate(path, { replace: true })
  };

  const [t] = useTranslation("global");

  const [opened, { toggle }] = useDisclosure();

  const locationChange = useLocation();

  useEffect(() => {
    googleAnalyticsLogger().log(locationChange.pathname);
  }, [locationChange]);

  return (
    <>
      <Flex justify="center" bg="#000">
        <Text component='p' color={theme.colors.yellow[5]}>Nadograđujemo / upgrading</Text>
      </Flex>

      <MantineHeader height={100} pos="relative" px="xl" className={classes.headerStyles}>

        <Flex justify="space-between">
  
          <Flex align="center">

            <MediaQuery largerThan="sm" styles={{display: 'none'}}>
              <Burger
                opened={opened}
                onClick={toggle}
                mr={30}
              />
            </MediaQuery>
            
            <ActionIcon variant="transparent" mr={50} h={96} w={96} onClick={() => navigateTo('/')}>
              <Image src={Logo} alt='Oak Beam Software Development Logo' />
            </ActionIcon>

            <MediaQuery smallerThan="sm" styles={{display: 'none'}}>

              <div>
                <UnstyledButton className={classes.mainLink} onClick={() => navigateTo('/')}>
                  <Text component="a" fs="xl">
                    { t("header.navbuttons.home") }
                  </Text>
                </UnstyledButton>

                <UnstyledButton className={classes.mainLink} onClick={() => navigateTo('/request-an-offer')}>
                  <Text component="a" fs="xl">
                    { t("header.navbuttons.request_an_offer") }
                  </Text>
                </UnstyledButton>

                <UnstyledButton className={classes.mainLink} onClick={() => navigateTo('/about')}>
                  <Text component="a" fs="xl">
                    { t("header.navbuttons.about") }
                  </Text>
                </UnstyledButton>
              </div>

            </MediaQuery>
            
          </Flex>

            <LanguagePicker visible={useMediaQuery('(min-width: 56.25em)')} />

        </Flex>
        
      </MantineHeader>

        <Drawer
          opened={opened}
          onClose={toggle}
          size="100%"
          padding="md"
          title="Navigation"
          zIndex={1000000}
        >
          <Divider
            my="sm"
          />
  
          <Stack>

            <UnstyledButton className={classes.mainLink} onClick={() => {navigateTo('/'); toggle()}}>
              <Text component="a" fs="xl">
                { t("header.navbuttons.home") }
              </Text>
            </UnstyledButton>

            <UnstyledButton className={classes.mainLink} onClick={() => {navigateTo('/request-an-offer'); toggle()}}>
              <Text component="a" fs="xl">
                { t("header.navbuttons.request_an_offer") }
              </Text>
            </UnstyledButton>

            <UnstyledButton className={classes.mainLink} onClick={() => {navigateTo('/about'); toggle()}}>
              <Text component="a" fs="xl">
                { t("header.navbuttons.about") }
              </Text>
            </UnstyledButton>

            <Divider my="xs" />

            <Center>
              <LanguagePicker />
            </Center>

          </Stack>
          
        </Drawer>

      </>
  );
}
  
export default Header;