import { MantineProvider, MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em',
  },
  colorScheme: 'dark',
  colors: {
    'brown': ["#f7f3f2", "#e7e5e5", "#d2c9c6", "#bdaaa4", "#ab9087", "#a17f75", "#9d766b", "#896459", "#7b584e", "#6d4b40"],
    gray: [
      "#fef2f5",
      "#e5e9e5",
      "#cccecc",
      "#b1b4b1",
      "#9a9c9a",
      "#8b8d8a",
      "#828782",
      "#6f746f",
      "#616861",
      "#515b51"
    ],
    'obBlue': ["#0e1425", "#121a2e", "#162034", "#1a263b", "#1f2b45", "#2b3660", "#3b467b", "#4b568f", "#5c67a3", "#14192d"]
  },
  defaultRadius: 'lg',
  lineHeight: 2,
  primaryColor: 'obBlue',
  primaryShade: 9,
  components: {
    AppShell: {
      styles: {
        root: {
          background: `var(--mantine-color-obBlue-9)`
        },
        main: {
          minHeight: `calc(100vh - var(--mantine-header-height, 0px) - 156px)` // 100px header, 156 px footer
        }
      }
    },
    Menu: {
      styles: {
        dropdown: {
          background: `var(--mantine-color-obBlue-9)`,
        }
      }
    },
    MultiSelect: {
      styles: {
        dropdown: {
          background: `var(--mantine-color-obBlue-9)`
        }
      }
    },
    Anchor: {
      styles: {
        root: {
          color: '#6897ff'
        }
      }
    }
  },
};

interface ThemeProviderProps {
  children: React.ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  
  return (
    <MantineProvider withNormalizeCSS withGlobalStyles withCSSVariables theme={theme}>
      {children}
    </MantineProvider>
  );
}
