import { ThemeProvider } from "./ThemeProvider";
import Header from './components/Header';
import AllRoutes from "./routes";
import './styles/global.scss';
import Footer from "./components/Footer";
import { Suspense } from "react";
import { AppShell, createStyles, } from "@mantine/core";
import { BrowserRouter } from "react-router-dom";
import Consent from "./components/Consent";
import {Helmet} from "react-helmet";

const useStyles = createStyles(() => ({
  appMain: {
    padding: 0,
    width: '100%'
  },
  appRoot: {
    overflowX: 'hidden'
  }
}));

export default function App() {

  const { classes } = useStyles();

  return (
    <>
      <Helmet>
        <title>Oak Beam Software Development</title>
        <meta
          name="description"
          content="Pružamo izradu softverskih rješenja prema vašim potrebama. Odaberite web tehnologiju iz naše ponude za razvoj Vaše aplikacije."
        />
        <meta property="og:title" content="Oak Beam Software Development / Razvoj softvera" />
        <meta property="og:type" content="business.business" />
      </Helmet>

      <ThemeProvider>
        <BrowserRouter>

          <AppShell
            header={<Header />}
            footer={<Footer />}
            p={0}
            classNames={{ main: classes.appMain, root: classes.appRoot }}
            >
              
            <Suspense fallback={undefined}>
              <AllRoutes />
            </Suspense>  

          </AppShell>

          <Consent />

        </BrowserRouter>   

      </ThemeProvider>
    </>
  );
}
